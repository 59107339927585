.modal-begin {
    position: fixed;
    top: 412px;
    right: -0.125rem;
    z-index: 50;
    transform-origin: 90% center;
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@media (min-width: 768px) {
    .modal-begin {
        top: 537px;
        right: 0px
    }
}
